<template>
  <Head :data="data"/>
  <div :style="{'padding-top': subChannelListData.length>0 || channelContent.single?'46px':'' }">
    <van-tabs v-model:active="active" v-if="subChannelListData.length > 0" @click-tab="tabClick"
              title-active-color="#1989fa" color="#1989fa">
      <van-tab v-for="(item,index) in subChannelListData" :title="item.name" :name="item.id" :key="index">
      </van-tab>
    </van-tabs>
    <!--栏目不为单页-->
    <div v-show="!channelContent.single">
      <!--新闻列表-->
      <div class="news-list">
        <van-cell-group>
            <!--产品-->
            <div class="product-list" v-if="channelId==1">
              <div class="product-item" v-for="(item, index) in contentListData" @click="contentClick(item.id)">
                <img v-if="!item.error" :src="item.thumbnail!= ''? serverURL+item.thumbnail: img" @error="item.error=true"/>
                <img v-else src="../../assets/images/front/no-pic-mbzs.png"/>
                <span>{{item.title}}</span>
              </div>
            </div>
            <!--常见问题-->
            <div v-else-if="channelId==2&&subChannelId==4">
              <van-cell class="problem-item" v-for="(item, index) in contentListData" :key="index"
                        @click="contentClick(item.id)">
                <template #title>
                  <span>Q：</span>{{ item.title }}
                </template>
                <template #label>
                  <span v-html="strUtil.cutTitle(item.content, 80)"></span>
                </template>
              </van-cell>
            </div>
            <!--通用-->
            <div v-else>
              <van-cell class="news-item" v-for="(item, index) in contentListData" :key="index">
                <van-card
                    @click="contentClick(item.id)"
                    :title="item.title"
                    :thumb="item.thumbnail!= ''? serverURL+item.thumbnail: img"
                >
                  <template #desc>
                    <div class="content" v-html="strUtil.cutTitle(strUtil.removeTags(item.content), 30)"></div>
                  </template>
                </van-card>
              </van-cell>
            </div>
            <div class="more" :style="`color:${colorMore}`" @click="loadMore==1?loadMoreData():''">{{ loadMoreText }}
            </div>
        </van-cell-group>
      </div>
    </div>

    <!--栏目为单页-->
    <div v-show="channelContent.single" style="margin: 0 10px;" :style="{'padding-top': subChannelListData.length>0?'46px':'' }">
      <div class="content" v-html="channelContent.content"></div>
    </div>
  </div>
</template>

<script>
import {ref, nextTick, onMounted, computed} from "vue";
import {useRoute, useRouter, onBeforeRouteUpdate} from 'vue-router';
import 'vant/lib/index.css';

import Head from '@/components/h5/Head'

import contentApi from "@/api/info/content";
import channelApi from "@/api/info/channel"
import config from '@/api/config'
import replaceEditorImgURL from "@/utils/replaceEditorImgURL";
import variableApi from "@/api/system/variable";
import strUtil from "@/utils/strUtil";

export default {
  components: {
    Head
  },
  watch: {
    // 利用watch方法检测路由变化
    '$route': function (to, from) {
      if (to.path == '/h5/channel') {
        this.loadMoreText = '';
        this.contentListData = [];
        this.channelId = this.$route.query.channelId;
        this.subChannelId = this.$route.query.channelId;
        this.channelName = this.$route.query.channelName;
        this.data.title = this.$route.query.channelName;
        this.queryChildrenChannel();
        this.queryChannelContent(this.$route.query.channelId);
        this.queryContentList(this.$route.query.channelId);
        // 如果是联系我们，加载地图
        this.initMapJs(this.channelName);
      }
    }
  },
  setup() {

    const data = ref({
      sign: true,
      title: '栏目页',
      back: true
    });
    const channelId = ref('');
    const channelName = ref('');
    const img = require("../../assets/images/front/no-pic-pc.png")
    const serverURL = ref('')
    const active = ref(0);

    const route = useRoute();
    const router = useRouter();
    const pageSize = ref(12);
    const currentPage = ref(1);
    const total = ref(0);

    const subChannelListData = ref([]);
    const contentListData = ref([]);
    const channelContent = ref([]);
    const subChannelId = ref();

    const colorMore = ref('');
    const loadMore = ref();
    const loadMoreText = ref();
    const mapCenter = ref([]);// 地图中心点坐标
    const cemetery = ref('');// 墓园名称

    // 下拉刷新
    const refreshing = ref('');

    // 初始化地图，引入js
    const initMapJs = (channelName) => {
      // 如果是联系我们，加载地图
      if (channelName == '联系我们') {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://webapi.amap.com/maps?v=2.0&key=c30ecf7cb88987713f3fdff3948d6952';
        document.body.appendChild(script);
        script.onload = () => {
          loadMap();
        };
      }
    }
    // 加载地图
    const loadMap = () => {
      // 请求获取坐标和名称
      variableApi.queryVarNumVariable({"varNum": 'location'}).then(response => {
        if (response.data.code == 1) {
          mapCenter.value = JSON.parse(response.data.data.location);
          variableApi.queryVarNumVariable({"varNum": 'cemetery'}).then(response => {
            if (response.data.code == 1) {
              cemetery.value = response.data.data.cemetery;
              // 渲染地图
              var map = new AMap.Map("mapContainer", {
                zoom: 16,
                center: mapCenter.value
              });
              // 添加标记点
              var marker = new AMap.Marker({
                map: map,
                position: mapCenter.value,
                label: {
                  offset: new AMap.Pixel(-30, -30),//修改label相对于maker的位置
                  content: "点击导航"
                }
              })
              // 标记点事件
              let cemeteryName = cemetery.value;
              let lngLat = mapCenter.value;
              marker.on('click', function (e) {
                let url = "https://m.amap.com/share/index/__q=[lat],[lng],[name]&src=jsapi&callnative=1&callapp=0&lnglat=[lng],[lat]&name=[name]";
                const aLink = document.createElement('a')
                aLink.style.display = 'none'
                // aLink.target="_blank";
                url = url.replaceAll('[lng]', lngLat[0]).replaceAll('[lat]', lngLat[1]).replaceAll('[name]', encodeURI(cemeteryName))
                aLink.href = url
                document.body.appendChild(aLink)
                aLink.click()
                URL.revokeObjectURL(aLink.href);//清除引用
                document.body.removeChild(aLink);

                /*marker.markOnAMAP({
                  name:cemeteryName,
                  position:marker.getPosition()
                })*/
              })
            }
          })
        }
      })
    }
    //获取当前栏目下子栏目
    const queryChildrenChannel = () => {
      channelApi.queryChildChannel(channelId.value)
          .then((response) => {
            subChannelListData.value = response.data.data;
            if (subChannelListData.value.length > 0) {
              //页面加载完成取第一个子栏目id
              subChannelId.value = subChannelListData.value[0].id;
              queryContentList(subChannelId.value)
              queryChannelContent(subChannelId.value)
            } else {
              queryContentList(route.query.channelId)
            }

          });
    };

    //切换tab 得到id， name=id
    const tabClick = ({name}) => {
      loadMoreText.value = ref();
      refreshing.value = false;
      subChannelId.value = name;
      currentPage.value = 1;
      contentListData.value = []
      queryContentList(name);
      queryChannelContent(name);

    };

    //查询内容列表
    const queryContentList = (channelId, sign) => {
      contentApi.pageContentByChannelId({
        pageSize: pageSize.value,
        curPage: currentPage.value,
        channelId: channelId
      }).then((res) => {
        total.value = res.data.data.total;
        let datas = res.data.data.records;

        //加载更多
        sign ? contentListData.value.push(...datas) : contentListData.value = datas;

        if (contentListData.value.length < total.value) {

          loadMore.value = 1;
          loadMoreText.value = '加载更多';
          colorMore.value = '#409eff';

        } else {

          colorMore.value = '#969799';
          loadMore.value = 0;
          loadMoreText.value = '没有更多了';
        }
        refreshing.value = false;
      })
    };

    //根据栏目id查询栏目内容
    const queryChannelContent = (channelId) => {
      channelApi.queryChannelContent(channelId).then(res => {
        channelContent.value.name = res.data.data.name;
        channelContent.value.single = res.data.data.single;
        if (res.data.data.content != null) {
          channelContent.value.content = replaceEditorImgURL.toReplaceEditorImgURL(res.data.data.content);
        }
      })
    };


    //下拉刷新
    const onRefresh = () => {
      queryContentList(subChannelId.value, false);
    };

    //加载更多
    const loadMoreData = () => {
      currentPage.value++;
      queryContentList(subChannelId.value, true);
    };

    const getCurrentTime = (releaseDate) => {

      let nowDate = new Date()
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      }
      var currentDate = date.year + '-' + (date.month < 10 ? '0' + date.month : date.month) + '-' + date.date;
      return currentDate == releaseDate.substring(0, 10);
    };

    const contentHeight = computed(() => {
      //屏幕高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      return (windowHeight - 54 - 50) + 'px';

    })

    //点击列表查看文章内容
    const contentClick = (id) => {
      router.push({
        path: '/h5/content',
        query: {
          contentId: id,
          channelId: channelId.value,
          channelName: channelName.value
        }
      })
    };

    onMounted(() => {
      serverURL.value = config.server;
      channelId.value = route.query.channelId;
      subChannelId.value = route.query.channelId;
      channelName.value = route.query.channelName;
      data.value.title = route.query.channelName;
      queryChildrenChannel();
      queryChannelContent(route.query.channelId);
      // 如果是联系我们，加载地图
      // initMapJs(channelName.value);
    });

    return {
      img,
      data,
      total,
      active,
      loadMore,
      pageSize,
      tabClick,
      serverURL,
      onRefresh,
      channelId,
      colorMore,
      refreshing,
      channelName,
      currentPage,
      subChannelId,
      loadMoreText,
      contentClick,
      loadMoreData,
      contentHeight,
      channelContent,
      getCurrentTime,
      contentListData,
      queryContentList,
      subChannelListData,
      queryChannelContent,
      queryChildrenChannel,
      mapCenter,
      cemetery,
      initMapJs,
      strUtil,
    }
  }

}
</script>

<style scoped>
.nav {
  padding-left: 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

.van-tabs {
  position: fixed;
  z-index: 110;
  width: 100%;
}

.van-nav-bar {
  background-color: #409eff;
}

:deep().van-nav-bar__title, :deep().van-nav-bar .van-icon {
  color: white;
}

.news-list {
  height: 100%;
  height: 100%;
  padding-top: 45px;
}

.van-divider {
  margin: 2px 0 0 0;
}

:deep().van-cell {
  padding: 5px 10px 5px 0;
}

:deep().van-card {
  background-color: white;
  /*height: 4.3rem;*/
}

:deep().van-card__title {
  font-size: 14px;
  font-weight: 700;
  /*color: #409eff;*/
}

:deep().van-card__content {
  justify-content: space-between;
  /*font-size: 13px;*/

}

:deep().van-card__thumb {
  /*height: 4.3rem;*/
  /*width: 6.4rem;*/
}

:deep().van-badge--top-left {
  top: 0.5rem;
  left: 1.2rem;
}

/*.content p strong {
  color: #8c939d !important;
}*/

.more {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.content {
  overflow: scroll;
}

#mapContainer {
  height: 300px;
}

.product-list{
  height: auto;
  padding: 10px;
}
.product-item{
  display: inline-block;
  width: calc(33.3% - 10px);
  text-align: center;
  margin-bottom: 10px;
  margin-right: 10px;
}
.product-item img {
  width: 100%;
  height: 150px;
}

.product-item span {
  display: block;
  font-size: 14px;
}

.problem-item {
  padding: 10px;
}


</style>